/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FB_ADS_ACCOUNTS,
  WORKSPACE_USER_DATA,
  PLATFROM_SERVICES,
  USER_DATA,
  WORKSPACE_ID,
  IS_REBILLING,
} from "../types/app";

const INITIAL_STATE = {
  fbAdsAccounts: [],
  platformServices: {},
};

const appReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case USER_DATA:
      return { ...state, userData: action.userData };
    case WORKSPACE_USER_DATA:
      return { ...state, workspaceUserData: action.workspaceUserData };
    case PLATFROM_SERVICES:
      return { ...state, platformServices: action.platformServices };
    case WORKSPACE_ID:
      return { ...state, workspaceId: action.workspaceId };
    case FB_ADS_ACCOUNTS:
      return { ...state, fbAdsAccounts: action.fbAdsAccounts };
    case IS_REBILLING:
      return { ...state, isRebilling: action.isRebilling };
    default: {
      return state;
    }
  }
};

export default appReducer;
