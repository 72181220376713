/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

//react
import "./styles.css";

//npm
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

//components
import { generateKey } from "functions/global";
import { Oval } from "react-loader-spinner";

type TreeProps = {
  data: any;
  loading?: boolean;
  onSelect: (item: any) => void;
  onChildSelect: (item: any) => void;
  isNotIcon?: boolean;
  isPreIcon?: boolean;
  selectedItems?: any[];
  isShowCheck?: boolean;
  isRemove?: boolean;
  onRemove?: (item: any) => void;
};

const MaterialTreeView = (props: TreeProps) => {
  const {
    data,
    loading,
    onSelect,
    onChildSelect,
    isNotIcon,
    isPreIcon,
    selectedItems,
    isShowCheck,
    isRemove,
    onRemove,
  } = props;

  const renderIcon = (icon: any) => {
    return (
      <img
        alt={""}
        className={"tree-view-icon"}
        src={"images/global/user-fill.png"}
      />
    );
  };

  const renderExpandCollapseIcon = (isExpand?: any) => {
    return isExpand ? (
      <img
        alt={""}
        className={`tree-label-icon ${isPreIcon && "tree-label-icon-dynamic"}`}
        src={"images/global/down-chevron.png"}
      />
    ) : (
      <img
        alt={""}
        className={`tree-label-icon ${isPreIcon && "tree-label-icon-dynamic-rotate"
          }`}
        src={"images/global/arrow_right.png"}
      />
    );
  };

  const renderLabel = (name?: any, item?: any) => {
    return (
      <div
        className={`tree-label ${isPreIcon && !isRemove && "tree-label-dynamics"
          }`}
      >
        <p>{name.charAt(0).toUpperCase() + name.slice(1)}</p>
        {!isPreIcon && (
          <img
            alt={""}
            className={"tree-label-icon"}
            src={"images/global/down-chevron.png"}
          />
        )}

        {isRemove && item && (
          <img
            alt={""}
            className={"tree-label-icon"}
            src={"images/global/close.png"}
            onClick={() => onRemove && onRemove(item)}
          />
        )}
      </div>
    );
  };

  const renderChildLabel = (name = "", id = '') => {
    const isChecked =
      selectedItems?.length &&
      selectedItems.find((item: any) => item.name === name);
    name = String(name);
    return (
      <div className={`tree-label`}>
        <div>
          <p style={{ margin: 0, }}>{name?.charAt(0)?.toUpperCase() + name?.slice(1)}</p>
          {id && <p style={{ margin: 0, color:'gray', fontSize:'12px' }}>{id}</p>}
        </div>
        {isShowCheck && (
          <label className={"tree-checkbox-container"}>
            <input checked={isChecked} type={"checkbox"} name={name} />
            <span className="tree-checkmark"></span>
          </label>
        )}
      </div>
    );
  };

  return loading ? (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Oval
        height={50}
        width={50}
        color="var(--color-primary)"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="var(--text-grey)"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  ) : (
    <div>
      {data.map((item: any, index: any) => {
        let {
          id,
          name,
          title,
          child,
          customerId,
          displayName,
          childAccounts,
          advertiser_id,
          advertiser_name,
        } = item;

        const parentId = customerId || advertiser_id || id || index;
        const parentIcon = "images/global/filter.png";
        child = child || childAccounts;
        title = advertiser_name || title || displayName || name;

        return (
          <TreeView
            key={parentId}
            defaultCollapseIcon={renderExpandCollapseIcon(true)}
            defaultExpandIcon={renderExpandCollapseIcon()}
          >
            {child?.length ? (
              <TreeItem
                key={index}
                nodeId={generateKey()}
                label={renderLabel(title, item)}
                icon={!isNotIcon ? renderIcon(parentIcon) : false}
              >
                {child.map((item: any, _ind: any) => {
                  let {
                    id,
                    name,
                    title,
                    customerId,
                    displayName,
                    advertiser_id,
                    advertiser_name,
                    timezone,
                    child,
                    childAccounts,
                    changeAuditStamps,
                  } = item;
                  child = child || childAccounts;
                  title = advertiser_name || title || displayName || name;
                  const icon = customerId
                    ? "images/global/google-icon.png"
                    : advertiser_id
                      ? "images/global/tiktok.png"
                      : id && timezone
                        ? "images/global/snapchat.png"
                        : changeAuditStamps
                          ? "images/global/linkedin.png"
                          : "images/global/facebook.png";
                  return child?.length ? (
                    <TreeItem
                      key={index}
                      nodeId={generateKey()}
                      label={renderLabel(title)}
                      icon={!isNotIcon ? renderIcon(parentIcon) : false}
                    >
                      {child.map((item: any, _ind: any) => {
                        let {
                          name,
                          title,
                          customerId,
                          displayName,
                          advertiser_id,
                          advertiser_name,
                          child,
                          childAccounts,
                          changeAuditStamps,
                          timezone,
                        } = item;
                        title = advertiser_name || title || displayName || name;
                        const icon = customerId
                          ? "images/global/google-icon.png"
                          : advertiser_id
                            ? "images/global/tiktok.png"
                            : id && timezone
                              ? "images/global/snapchat.png"
                              : changeAuditStamps
                                ? "images/global/linkedin.png"
                                : "images/global/facebook.png";
                        child = child || childAccounts;
                        return child?.length ? (
                          <TreeItem
                            key={index}
                            nodeId={generateKey()}
                            label={renderLabel(title)}
                            icon={!isNotIcon ? renderIcon(parentIcon) : false}
                          >
                            {child.map((item: any, _ind: any) => {
                              let {
                                id,
                                name,
                                title,
                                customerId,
                                displayName,
                                advertiser_id,
                                advertiser_name,
                                changeAuditStamps,
                                timezone,
                              } = item;
                              title =
                                advertiser_name || title || displayName || name;
                              const icon = customerId
                                ? "images/global/google-icon.png"
                                : advertiser_id
                                  ? "images/global/tiktok.png"
                                  : id && timezone
                                    ? "images/global/snapchat.png"
                                    : changeAuditStamps
                                      ? "images/global/linkedin.png"
                                      : "images/global/facebook.png";

                              return (
                                <TreeItem
                                  label={title}
                                  key={generateKey()}
                                  nodeId={generateKey()}
                                  icon={!isNotIcon ? renderIcon(icon) : false}
                                  onClick={() => onChildSelect(item)}
                                />
                              );
                            })}
                          </TreeItem>
                        ) : (
                          <TreeItem
                            label={renderChildLabel(title, item?.customerId)}
                            key={generateKey()}
                            nodeId={generateKey()}
                            icon={!isNotIcon ? renderIcon(icon) : false}
                            onClick={() => onChildSelect(item)}
                          />
                        );
                      })}
                    </TreeItem>
                  ) : (
                    <TreeItem
                      label={renderChildLabel(title, item?.customerId)}
                      key={generateKey()}
                      nodeId={generateKey()}
                      icon={!isNotIcon ? renderIcon(icon) : false}
                      onClick={() => onChildSelect(item)}
                    />
                  );
                })}
              </TreeItem>
            ) : (
              <TreeItem
                label={renderChildLabel(title, item?.customerId)}
                key={generateKey()}
                nodeId={generateKey()}
                icon={!isNotIcon ? renderIcon(parentIcon) : false}
                onClick={() => onSelect(item)}
              />
            )}
          </TreeView>
        );
      })}
    </div>
  );
};

export default MaterialTreeView;
