import {
  PLATFORM_SERVICES_COLLECTION,
  USER_DATA_AND_CHATBOT_COLLECTION,
} from "constants/global/firebase-collections";
import { doc, onSnapshot } from "firebase/firestore";
import {
  FB_ADS_ACCOUNTS,
  IS_REBILLING,
  PLATFROM_SERVICES,
  USER_DATA,
  WORKSPACE_ID,
  WORKSPACE_USER_DATA,
} from "types/app";

export const getUserData = (id: any): any => {
  return (dispatch: any) => {
    onSnapshot(doc(USER_DATA_AND_CHATBOT_COLLECTION, id), (response) => {
      dispatch(
        getPlatfromServices(response.data()?.lastSelectedWorkspace?.workspaceId)
      );
      dispatch(
        getWorkspaceUserData(
          response.data()?.lastSelectedWorkspace?.workspaceId
        )
      );
      dispatch(setUserData(response.data() || {}));
    });
  };
};

export const getWorkspaceUserData = (id: any): any => {
  return (dispatch: any) => {
    onSnapshot(doc(USER_DATA_AND_CHATBOT_COLLECTION, id), (response) => {
      console.log(response.data(), "response.data()");
      dispatch(setWorkspaceUserData(response.data() || {}));
    });
  };
};

export const getPlatfromServices = (id: any): any => {
  return (dispatch: any) => {
    onSnapshot(doc(PLATFORM_SERVICES_COLLECTION, id), (response) => {
      dispatch(setPlatformServices(response.data() || {}));
    });
  };
};

export const setIsRebilling = (isRebilling: any) => {
  return {
    isRebilling,
    type: IS_REBILLING,
  };
};

export const setUserData = (userData: any) => {
  return {
    userData,
    type: USER_DATA,
  };
};

export const setWorkspaceUserData = (workspaceUserData: any) => {
  return {
    workspaceUserData,
    type: WORKSPACE_USER_DATA,
  };
};

export const setPlatformServices = (platformServices: any) => {
  return {
    platformServices,
    type: PLATFROM_SERVICES,
  };
};

export const setWorkspaceId = (workspaceId: any) => {
  console.log(workspaceId, "workspaceId");
  return {
    workspaceId,
    type: WORKSPACE_ID,
  };
};

export const setFbAdsAccounts = (fbAdsAccounts: any) => {
  return {
    type: FB_ADS_ACCOUNTS,
    fbAdsAccounts,
  };
};
