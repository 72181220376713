/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useEffect, useState } from "react";
import store from "store";

// Components
import AppButton from "components/AppButton";
import AppContainer from "components/AppContainer";
import AppModal from "components/AppModal";
import MaterialeCard from "components/MaterialCard";
import MaterialTreeView from "components/MaterialTreeView";
import SocialAdsAccountSelection from "components/SocialAdsAccountSelection";
import { Oval } from "react-loader-spinner";
import { deleteField, doc, updateDoc } from "firebase/firestore";

//Constant
import {
  PLATFORM_SERVICES_COLLECTION,
  USER_DATA_AND_CHATBOT_COLLECTION,
} from "constants/global/firebase-collections";

//Funtions
import {
  _onSelectAdsItem,
  actionAlert,
  formatMessageInPlaceholder,
  isOnline,
  sweetAlert,
  updatePlatformService,
  updatePlatformServices,
  updateUserDataAndChatbot,
} from "functions/global";
import { fbAdsLogin, getFbAdsAccounts } from "functions/facebookAds";
import { getGoogleAdsAccounts } from "functions/googleAds";
import {
  getTikTokAccessToken,
  getTikTokAdvertiserAccounts,
  getTiktokAdvertiserDetail,
} from "functions/tiktokAds";
import {
  getSnapchatAccesstoken,
  getSnapchatOrganizations,
  getSnapchatUserInfo,
} from "functions/snapchatAds";
import {
  getLinkedinAccessToken,
  getLinkedinAdAccounts,
} from "functions/linkedin";

//Styles
import "./styles.css";
import Input from "components/Input";
import { IS_GOOGLE_DISABLED } from "constants/global/flags";
import {
  getGoogleAnalyticsAccount,
  getGoogleAnalyticsProperties,
  getGoogleAnalyticsViewIds,
} from "functions/googleAnalytics";
import { AppPopover } from "components/AppPopover";
import { getBingAdAccounts, getBingAdsAccessToken } from "functions/bingAds";
import {
  getSpotifyAdAccounts,
  getSpotifyAdsAccessToken,
} from "functions/spotifyAds";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import {
  Avatar,
  Button,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import AppText from "components/AppText";
import { useSelector } from "react-redux";

const AdsLogin = (props: any) => {
  const isCreatingAdAccount = false;
  const [loadingMoreAccounts, setLoadingMoreAccounts] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState<string | null>(null);
  const [adsAccounts, setAdsAccounts] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [accountsModalType, setAccountsModalType] = useState<string>();
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  // Facebook
  const [facebookAds, setFacebookAds] = useState<any>({});
  const [fbSwitchAccountLoader, setFbSwitchAccountLoader] = useState(false);

  // Google
  const [googleSwitchAccountLoader, setGoogleSwitchAccountLoader] =
    useState(false);
  const [googleAdwords, setGoogleAdwords] = useState<any>();

  //diable
  const [googleAdAccountId, setGoogleAdAccountId] = useState("");
  const [isGoogleAccManager, setIsGoogleAccManager] = useState("Yes");
  const [isAddAdAccModal, setIsAddAdAccModal] = useState(false);
  const [modalAdAccId, setModalAdAccId] = useState("");
  const [googleSelectedAdAccountList, setGoogleSelectedAdAccountList] =
    useState<string[]>([]);
  const [isShowSelectedAccIds, setIsShowSelectedAccIds] = useState(false);

  //tiktok
  const [tiktokAds, setTiktokAds] = useState<any>();
  const [isTiktokSwitchAccLoading, setIsTiktokSwitchAccLoading] =
    useState(false);
  const [isTiktokConnectLoading, setIsTiktokConnectLoading] = useState(false);
  const [Account, setAccount] = useState();
  const [redirectUrl, setRedirectUrl] = useState<string>();

  //snapchat
  const [isSnapchatConnectLoading, setIsSnapchatConnectLoading] =
    useState(false);
  const [snapchatAds, setSnapchatAds] = useState<any>({});
  const [isSnapchatSwitchAccLoading, setIsSnapchatSwitchAccLoading] =
    useState(false);
  const [snapchatSwitchType, setSnapchatSwitchType] = useState("");
  const [snapchatOrganizations, setSnapchatOrganizations] = useState([]);
  const [organizationLoader, setOrganizationLoader] = useState(false);
  const [snapchatAdAccounts, setSnapchatAdAccounts] = useState([]);

  //linkedin
  const [isLinkedinConnectLoading, setIsLinkedinConnectLoading] =
    useState(false);
  const [linkedinAds, setLinkedinAds] = useState<any>({});
  const [isLinkedinSwitchAccLoading, setIsLinkedinSwitchAccLoading] =
    useState(false);

  //bing ads
  const [isBingConnectLoading, setIsBingConnectLoading] = useState(false);
  const [bingAds, setBingAds] = useState<any>({});
  const [isBingSwitchAccLoading, setIsBingSwitchAccLoading] = useState(false);

  // Spotify ads
  const [isSpotifyConnectLoading, setIsSpotifyConnectLoading] = useState(false);
  const [spotifyAds, setSpotifyAds] = useState<any>({});
  const [isSpotifySwitchAccLoading, setIsSpotifySwitchAccLoading] =
    useState(false);

  const [workspaceId, setWorkspaceId] = useState();
  const [urlState, setUrlState] = useState();

  //Analytics
  const [
    googleAnalyticsSwitchAccountLoader,
    setGoogleAnalyticsSwitchAccountLoader,
  ] = useState(false);
  const [googleAnalytics, setGoogleAnalytics] = useState<any>();
  const [
    googleAnalyticsSwitchPropertyLoader,
    setGoogleAnalyticsSwitchPropertyLoader,
  ] = useState<boolean>(false);
  const [googleAnalyticType, setGoogleAnalyticType] = useState<string>("");
  const [
    googleAnalyticsSwitchViewIdLoader,
    setGoogleAnalyticsSwitchViewIdLoader,
  ] = useState<boolean>(false);
  //funtions========================================================
  useEffect(() => {
    getStateFromStoreWoSubscribe();
    getStateFromStore();
    const unsubscribe = store.subscribe(() => {
      getStateFromStore();
    });

    return unsubscribe;
  }, []);

  const { isRebilling = false }: any = useSelector((state: any) => state.appReducer)

  useEffect(() => {
    const params: any = props?.params;
    const auth_code = params?.get("auth_code");
    const redirect_auth_code = params?.get("code");
    const state = JSON?.parse(params?.get("state"));
    const redirectUrl = JSON?.parse(params?.get("redirect_url"));
    const id: any = params?.get("id") || state?.id;
    const type = state?.Account;
    console.log(type, "check type");

    if (type === "SNAPCHAT") {
      setIsSnapchatConnectLoading(true);
      _getSnapchatAccessToken(redirect_auth_code);
    } else if (type === "LINKEDIN") {
      setIsLinkedinConnectLoading(true);
      _getLinkedinAccessToken(redirect_auth_code);
    } else if (type === "BING_ADS") {
      _getBingAdsAccessToken(redirect_auth_code);
    } else if (type === "SPOTIFY") {
      _getSpotifyAdsAccessToken(redirect_auth_code);
    } else {
      _getTikTokAccessToken(auth_code);
    }
    setAccount(state?.Account);
    setRedirectUrl(
      (type === "TIKTOK"
        ? state?.redirectUrl?.replace(",", "&")
        : state?.redirectUrl) || redirectUrl
    );
    setWorkspaceId(id);
    setUrlState(state);
  }, [props?.params]);

  const getStateFromStore = () => {
    const { platformServices = {} }: any = store.getState().appReducer;

    const {
      facebookAds = {},
      googleAdwords = {},
      googleAnalytics = {},
      tiktokAds = {},
      snapchatAds = {},
      linkedinAds = {},
      bingAds = {},
      spotifyAds = {},
    } = platformServices;

    setFacebookAds(facebookAds);
    setGoogleAdwords(googleAdwords);
    setTiktokAds(tiktokAds);
    setSnapchatAds(snapchatAds);
    setLinkedinAds(linkedinAds);
    setGoogleAnalytics(googleAnalytics);
    setBingAds(bingAds);
    setSpotifyAds(spotifyAds);
  };

  const getStateFromStoreWoSubscribe = () => {
    const { workspaceUserData = {} }: any = store.getState().appReducer;
    const { platformServices = {} }: any = store.getState().appReducer;

    const { googleAdwords = {} } = platformServices;

    const { googleAdAccountId = "", googleSelectedAdAccountList = [] } =
      workspaceUserData;

    if (googleAdwords?.id || googleAdAccountId) {
      setGoogleAdAccountId(googleAdAccountId || googleAdwords?.id || "");
    }

    setGoogleSelectedAdAccountList(googleSelectedAdAccountList);
  };

  //Google
  const _getGoogleAdsAccounts = async (type: string) => {
    setGoogleSwitchAccountLoader(true);
    const { refreshToken } = googleAdwords;
    const googleAdsAccounts = await getGoogleAdsAccounts(refreshToken);

    if (googleAdsAccounts?.length) {
      setShowModal(true);
      setAccountsModalType(type);
      setAdsAccounts([...googleAdsAccounts]);
    } else {
      sweetAlert(
        "You don't have google ads account",
        "error",
        null,
        null,
        "https://aiads.tawk.help/category/creating-ad-accounts",
        " https://aiads.tawk.help/category/creating-ad-accounts"
      );
    }

    setGoogleSwitchAccountLoader(false);
  };

  const validateGoogleAdsAccountId = () => {
    // const { accountId } = googleAdwords;
    if (!modalAdAccId.trim()) {
      sweetAlert("Please Enter Google Ads account Id");
    } else if (modalAdAccId.trim().length < 12) {
      sweetAlert("Google Ads account Id must be of 10 characters.");
    } else {
      googleSelectedAdAccountList.push(modalAdAccId);
      setGoogleSelectedAdAccountList([...googleSelectedAdAccountList]);
      setGoogleAdAccountId(modalAdAccId);
      setIsAddAdAccModal(false);
      setModalAdAccId("");
    }
  };

  //Tiktok
  const _getTikTokAccessToken = async (authCode: any) => {
    try {
      setIsTiktokConnectLoading(true);
      const responseAccestoken = await getTikTokAccessToken(authCode);
      const response = await getTikTokAdvertiserAccounts(responseAccestoken);

      if (response) {
        if (response.length === 1) {
          const tiktokCurrencyCode = await getTiktokAdvertiserDetail(
            response[0]?.advertiser_id,
            responseAccestoken
          );

          updatePlatformService(
            "tiktokAds.accountId",
            "tiktokAds.accountName",
            response[0],
            "tiktokAds.currencyCode",
            tiktokCurrencyCode
          );
        }
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsTiktokConnectLoading(false);
    }
  };

  const _getTikTokAdvertiserAccounts = async (type: any) => {
    try {
      setIsTiktokSwitchAccLoading(true);
      const response = await getTikTokAdvertiserAccounts();
      if (response.length) {
        setShowModal(true);
        setAccountsModalType(type);
        setAdsAccounts([...response]);
      } else {
        sweetAlert(
          "You don't have tiktok ads account",
          "error",
          null,
          null,
          "https://aiads.tawk.help/category/creating-ad-accounts",
          " https://aiads.tawk.help/category/creating-ad-accounts"
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsTiktokSwitchAccLoading(false);
    }
  };

  const removeTikTokPlatform = () => {
    const { userData = {} }: any = store.getState().appReducer;
    const { lastSelectedWorkspace = {} } = userData;
    const { workspaceId } = lastSelectedWorkspace;

    const ref = doc(PLATFORM_SERVICES_COLLECTION, workspaceId);
    updateDoc(ref, {
      tiktokAds: deleteField(),
    });

    const docRef = doc(USER_DATA_AND_CHATBOT_COLLECTION, workspaceId);
    updateDoc(docRef, {
      tiktokConversionGoal: deleteField(),
      tiktokPixelId: deleteField(),
    });
  };

  // SNAPCHAT AD
  const _getSnapchatAccessToken = async (code: any) => {
    try {
      const accessToken = await getSnapchatAccesstoken(code);
      if (accessToken) {
        await getSnapchatUserInfo(accessToken);
        const response = await getSnapchatOrganizations(accessToken);
        if (response?.length) {
          setSnapchatOrganizations(response);
          if (response?.length === 1) {
            updatePlatformServices(
              "snapchatAds.organizationId",
              response[0]?.id || ""
            );
            updatePlatformServices(
              "snapchatAds.organizationName",
              response[0]?.name || ""
            );

            if (response[0].ad_accounts?.length === 1) {
              updatePlatformService(
                "snapchatAds.accountId",
                "snapchatAds.accountName",
                response[0].ad_accounts[0],
                "snapchatAds.currencyCode"
              );
            }
          }
        }
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsSnapchatConnectLoading(false);
    }
  };

  const _getSnapchatOrganizations = async (type: any) => {
    try {
      setOrganizationLoader(true);
      const response = await getSnapchatOrganizations();
      if (response?.length) {
        setShowModal(true);
        setAccountsModalType(type);
        setAdsAccounts([...response]);
        setSnapchatSwitchType("ORGANIZATION");
      } else {
        sweetAlert(
          "You don't have snapchat ads account",
          "error",
          null,
          null,
          "https://aiads.tawk.help/category/creating-ad-accounts",
          " https://aiads.tawk.help/category/creating-ad-accounts"
        );
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setOrganizationLoader(false);
    }
  };

  const _getSnapchatAdAccounts = async (type: any) => {
    try {
      setIsSnapchatSwitchAccLoading(true);
      if (snapchatAdAccounts?.length) {
        setShowModal(true);
        setAccountsModalType(type);
        setAdsAccounts([...snapchatAdAccounts]);
        setSnapchatSwitchType("ACCOUNT");
      } else {
        sweetAlert(
          "You don't have snapchat ads account",
          "error",
          null,
          null,
          "https://aiads.tawk.help/category/creating-ad-accounts",
          " https://aiads.tawk.help/category/creating-ad-accounts"
        );
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsSnapchatSwitchAccLoading(false);
    }
  };

  const removeSnapchatPlatform = () => {
    const { userData = {} }: any = store.getState().appReducer;
    const { lastSelectedWorkspace = {} } = userData;
    const { workspaceId } = lastSelectedWorkspace;

    const ref = doc(PLATFORM_SERVICES_COLLECTION, workspaceId);

    updateDoc(ref, {
      snapchatAds: deleteField(),
    });
  };

  // LINKEDIN AD
  const _getLinkedinAccessToken = async (code: any) => {
    try {
      await getLinkedinAccessToken(code);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLinkedinConnectLoading(false);
    }
  };

  const _getLinkedinAdAccounts = async (type: any) => {
    try {
      setIsLinkedinSwitchAccLoading(true);
      const response = await getLinkedinAdAccounts();
      if (response?.length) {
        setShowModal(true);
        setAccountsModalType(type);
        setAdsAccounts([...response]);
      } else {
        sweetAlert(
          "You don't have Linkedin ads account",
          "error",
          null,
          null,
          "https://aiads.tawk.help/category/creating-ad-accounts",
          " https://aiads.tawk.help/category/creating-ad-accounts"
        );
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLinkedinSwitchAccLoading(false);
    }
  };

  const removeLinkedinPlatform = () => {
    const { userData = {} }: any = store.getState().appReducer;
    const { lastSelectedWorkspace = {} } = userData;
    const { workspaceId } = lastSelectedWorkspace;

    const ref = doc(PLATFORM_SERVICES_COLLECTION, workspaceId);

    updateDoc(ref, {
      linkedinAds: deleteField(),
    });
  };

  // Spotify Ads
  const _getSpotifyAdsAccessToken = async (code: any) => {
    try {
      await getSpotifyAdsAccessToken(code);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsSpotifyConnectLoading(false);
    }
  };

  const _getSpotifyAdAccounts = async (type: any) => {
    try {
      setIsSpotifySwitchAccLoading(true);
      const response = await getSpotifyAdAccounts();
      if (response?.length) {
        setShowModal(true);
        setAccountsModalType(type);
        setAdsAccounts([...response]);
      } else {
        sweetAlert(
          "You don't have Spotify ads account",
          "error",
          null,
          null,
          "https://aiads.tawk.help/category/creating-ad-accounts",
          " https://aiads.tawk.help/category/creating-ad-accounts"
        );
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsSpotifySwitchAccLoading(false);
    }
  };

  // Bing Ads

  const _getBingAdsAccessToken = async (code: any) => {
    try {
      await getBingAdsAccessToken(code);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsBingConnectLoading(false);
    }
  };

  const removeBingAdPlatform = () => {
    const { userData = {} }: any = store.getState().appReducer;
    const { lastSelectedWorkspace = {} } = userData;
    const { workspaceId } = lastSelectedWorkspace;

    const ref = doc(PLATFORM_SERVICES_COLLECTION, workspaceId);

    updateDoc(ref, {
      bingAds: deleteField(),
    });
  };

  const removeSpotifyAdPlatform = () => {
    const { userData = {} }: any = store.getState().appReducer;
    const { lastSelectedWorkspace = {} } = userData;
    const { workspaceId } = lastSelectedWorkspace;

    const ref = doc(PLATFORM_SERVICES_COLLECTION, workspaceId);

    updateDoc(ref, {
      spotifyAds: deleteField(),
    });
  };

  const _getBingAdAccounts = async (type: any) => {
    try {
      setIsBingSwitchAccLoading(true);
      const response = await getBingAdAccounts();
      if (response?.length) {
        setShowModal(true);
        setAccountsModalType(type);
        setAdsAccounts([...response]);
      } else {
        sweetAlert(
          "You don't have Bing ads account",
          "error",
          null,
          null,
          "https://aiads.tawk.help/category/creating-ad-accounts",
          " https://aiads.tawk.help/category/creating-ad-accounts"
        );
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsBingSwitchAccLoading(false);
    }
  };

  //Analytics
  const _getGoogleAnalyticsAccounts = async (type: string) => {
    setGoogleAnalyticsSwitchAccountLoader(true);

    const { refreshToken } = googleAnalytics;
    const googleAnalyticsAccounts = await getGoogleAnalyticsAccount(
      refreshToken
    );

    if (googleAnalyticsAccounts?.error) {
      sweetAlert(googleAnalyticsAccounts?.error);
    } else {
      setShowModal(true);
      setAccountsModalType(type);
      setGoogleAnalyticType("accounts");
      setAdsAccounts([...googleAnalyticsAccounts]);
    }

    setGoogleAnalyticsSwitchAccountLoader(false);
  };

  const _getGoogleAnalyticsProperties = async (type: string) => {
    setGoogleAnalyticsSwitchPropertyLoader(true);

    const { refreshToken, accountId } = googleAnalytics;
    const obj = {
      refreshToken,
      accountId,
    };
    const googleAnalyticsProperties = await getGoogleAnalyticsProperties(obj);

    if (googleAnalyticsProperties?.length) {
      setShowModal(true);
      setAccountsModalType(type);
      setGoogleAnalyticType("properties");
      setAdsAccounts([...googleAnalyticsProperties]);
    } else {
      sweetAlert(`You don't have google analytics property.`);
    }

    setGoogleAnalyticsSwitchPropertyLoader(false);
  };

  const _getGoogleAnalyticsViewIds = async (type: string) => {
    setGoogleAnalyticsSwitchViewIdLoader(true);

    const { refreshToken, accountId, propertyId } = googleAnalytics;
    const obj = {
      accountId,
      refreshToken,
      propertyId,
    };

    const googleAnalyticsviewIds = await getGoogleAnalyticsViewIds(obj);

    if (googleAnalyticsviewIds?.length) {
      setShowModal(true);
      setAccountsModalType(type);
      setGoogleAnalyticType("views");
      setAdsAccounts([...googleAnalyticsviewIds]);
    } else {
      sweetAlert(`You don't have google analytics View id.`);
    }

    setGoogleAnalyticsSwitchViewIdLoader(false);
  };

  const onSelectGoogleAnalytics = (
    idKey: any,
    nameKey: any,
    { id, name, displayName, ad_accounts = [] }: any
  ) => {
    id = id || name;
    name = displayName || name;

    const { userData = {} }: any = store.getState().appReducer;
    const { lastSelectedWorkspace = {} } = userData;
    const { workspaceId } = lastSelectedWorkspace;

    updateDoc(doc(PLATFORM_SERVICES_COLLECTION, workspaceId), {
      [idKey]: id,
      [nameKey]: name,
    });

    if (
      accountsModalType === "SNAPCHAT" &&
      snapchatSwitchType === "ORGANIZATION"
    ) {
      setSnapchatAdAccounts(ad_accounts);
    }

    if (googleAnalyticType === "accounts") {
      updateDoc(doc(PLATFORM_SERVICES_COLLECTION, workspaceId), {
        "googleAnalytics.propertyId": "",
        "googleAnalytics.propertyName": "",
        "googleAnalytics.viewId": "",
        "googleAnalytics.viewName": "",
      });
    }

    if (googleAnalyticType === "properties") {
      updateDoc(doc(PLATFORM_SERVICES_COLLECTION, workspaceId), {
        "googleAnalytics.viewId": "",
        "googleAnalytics.viewName": "",
      });
    }
    setShowModal(false);
  };

  //Global

  const onSelectAdsItem = async (
    item: any,
    idKey: any,
    idName: any,
    currencyKey: any,
    type: any
  ) => {
    setIsModalLoading(true);

    const isSelected = await _onSelectAdsItem(
      item,
      idKey,
      idName,
      currencyKey,
      type,
      workspaceId
      // googleAdwords
    );

    if (!isSelected) {
      setShowModal(false);
      setIsModalLoading(false);
    }
  };

  const handleLoadMore = () => {
    if (nextPageUrl) {
      fetchData(nextPageUrl);
    }
  };

  const fetchData = async (url: string) => {
    try {
      setLoadingMoreAccounts(true);
      const response = await fetch(url);
      const data = await response.json();
      setAdsAccounts((prevAdAccounts: any) => [
        ...prevAdAccounts,
        ...data.data,
      ]);
      setNextPageUrl(data.paging.next);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingMoreAccounts(false);
    }
  };

  const switchAccount = async (type: any) => {
    if (isOnline()) {
      if (type === "FACEBOOK") {
        setFbSwitchAccountLoader(true);
        const fbAccounts: any = await getFbAdsAccounts("debugmessage3");

        if ((fbAccounts.data as any)?.length) {
          setShowModal(true);
          setAccountsModalType(type);
          setAdsAccounts([...fbAccounts.data]);
          setNextPageUrl(fbAccounts.paging.next);
        } else {
          sweetAlert(
            "You don't have facebook ads account",
            "error",
            null,
            null,
            "https://aiads.tawk.help/category/creating-ad-accounts",
            " https://aiads.tawk.help/category/creating-ad-accounts"
          );
        }
        setFbSwitchAccountLoader(false);
      } else if (type === "GOOGLE_ANALYTICS") {
        _getGoogleAnalyticsAccounts(type);
      } else if (type === "TIKTOK") {
        if (
          (tiktokAds as any)?.refreshToken ||
          (tiktokAds as any)?.mobileRefreshToken
        ) {
          await _getTikTokAdvertiserAccounts(type);
        } else {
          removeTikTokPlatform();
        }
      } else if (type === "SNAPCHAT") {
        if ((snapchatAds as any)?.refreshToken) {
          await _getSnapchatAdAccounts(type);
        }
      } else if (type === "LINKEDIN") {
        if ((linkedinAds as any)?.accessToken) {
          await _getLinkedinAdAccounts(type);
        }
      } else if (type === "BING_ADS") {
        if ((bingAds as any)?.refreshToken) {
          await _getBingAdAccounts(type);
        }
      } else if (type === "SPOTIFY") {
        if ((spotifyAds as any)?.refreshToken) {
          await _getSpotifyAdAccounts(type);
        }
      } else {
        _getGoogleAdsAccounts(type);
      }
    } else {
      sweetAlert(
        "Please make sure your device connected to internet!",
        null,
        "error"
      );
    }
  };

  //Render============================================================

  // Google ad disabled
  const renderAddAdAccountModal = () => {
    return (
      <AppModal
        modalWidth={"sm"}
        open={isAddAdAccModal}
        handleClose={() => setIsAddAdAccModal(false)}
      >
        <div className="google-ad-account-modal-container">
          <p className="google-ad-account-input-label">
            <AppText>Enter your ad account id</AppText>
          </p>
          <Input
            placeholder="Example: 111-000-1234"
            value={modalAdAccId}
            onChange={(e: any) => {
              if (e.target.value.length === 3) {
                const text = e.target.value.substring(0, 3) + "-";
                setModalAdAccId(text);
              } else if (e.target.value.length === 7) {
                const text = e.target.value.substring(0, 7) + "-";
                setModalAdAccId(text);
              } else {
                setModalAdAccId(e.target.value);
              }
            }}
            maxLength={12}
            onKeyUp={(e: any) => {
              if (e.key === "Backspace") {
                if (e.target.value.length === 4) {
                  setModalAdAccId(e.target.value.substring(0, 3));
                } else if (e.target.value.length === 8) {
                  setModalAdAccId(e.target.value.substring(0, 7));
                }
              }
            }}
            inputChildClassName="google-disable-ad-account-input"
          />
          <AppButton
            title="Add"
            onClick={validateGoogleAdsAccountId}
            className={"add-google-acc-id-btn"}
          />
        </div>
      </AppModal>
    );
  };

  const renderAdAccountIdsList = () => {
    return (
      <AppModal
        modalWidth={"xs"}
        open={isShowSelectedAccIds}
        handleClose={() => setIsShowSelectedAccIds(false)}
      >
        <div className="google-disable-acc-modal">
          <p className="google-disable-acc-modal-heading">
            <AppText>Ad accounts</AppText>
          </p>
          {googleSelectedAdAccountList.map((item: string, index: number) => {
            return (
              <div
                className="google-disable-acc-modal-container"
                onClick={() => {
                  setGoogleAdAccountId(item);
                  setIsShowSelectedAccIds(false);
                }}
              >
                <img
                  alt={""}
                  className={"tree-view-icon"}
                  src={"images/global/user-fill.png"}
                />
                <p className="google-disable-acc-modal-text">{item}</p>
              </div>
            );
          })}
        </div>
      </AppModal>
    );
  };

  // Google ad disabled

  const _renderAdAccounts = () => {
    const isTikTok = accountsModalType === "TIKTOK";
    const isFacebook = accountsModalType === "FACEBOOK";
    const isSnapchat = accountsModalType === "SNAPCHAT";
    const isLinkedin = accountsModalType === "LINKEDIN";
    const isBingAds = accountsModalType === "BING_ADS";
    const isSpotifyAds = accountsModalType === "SPOTIFY";

    const isGoogleAnalytics = accountsModalType === "GOOGLE_ANALYTICS";
    const isSnapchatOrganization =
      accountsModalType === "SNAPCHAT" && snapchatSwitchType === "ORGANIZATION";

    const idKey = isFacebook
      ? "facebookAds.accountId"
      : isGoogleAnalytics
      ? googleAnalyticType === "accounts"
        ? "googleAnalytics.accountId"
        : googleAnalyticType === "properties"
        ? "googleAnalytics.propertyId"
        : "googleAnalytics.viewId"
      : isTikTok
      ? "tiktokAds.accountId"
      : isSnapchat
      ? snapchatSwitchType === "ORGANIZATION"
        ? "snapchatAds.organizationId"
        : "snapchatAds.accountId"
      : isLinkedin
      ? "linkedinAds.accountId"
      : isBingAds
      ? "bingAds.accountId"
      : isSpotifyAds
      ? "spotifyAds.accountId"
      : "googleAdwords.accountId";

    const idName = isFacebook
      ? "facebookAds.accountName"
      : isGoogleAnalytics
      ? googleAnalyticType === "accounts"
        ? "googleAnalytics.accountName"
        : googleAnalyticType === "properties"
        ? "googleAnalytics.propertyName"
        : "googleAnalytics.viewName"
      : isTikTok
      ? "tiktokAds.accountName"
      : isSnapchat
      ? snapchatSwitchType === "ORGANIZATION"
        ? "snapchatAds.organizationName"
        : "snapchatAds.accountName"
      : isLinkedin
      ? "linkedinAds.accountName"
      : isBingAds
      ? "bingAds.accountName"
      : isSpotifyAds
      ? "spotifyAds.accountName"
      : "googleAdwords.accountName";

    const currencyKey = isFacebook
      ? "facebookAds.currencyCode"
      : isTikTok
      ? "tiktokAds.currencyCode"
      : isTikTok
      ? "tiktokAds.currencyCode"
      : isSnapchat
      ? "snapchatAds.currencyCode"
      : isLinkedin
      ? "linkedinAds.currencyCode"
      : isBingAds
      ? "bingAds.currencyCode"
      : isSpotifyAds
      ? "spotifyAds.currencyCode"
      : "googleAdwords.currencyCode";

    return (
      <AppModal open={showModal} handleClose={() => setShowModal(false)}>
        {isFacebook ? (
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
            }}
          >
            {adsAccounts.map((account: any) => (
              <ListItemButton
                onClick={() =>
                  onSelectAdsItem(
                    account,
                    idKey,
                    idName,
                    currencyKey,
                    accountsModalType
                  )
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <FontAwesomeIcon icon={faUser} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={account.name} secondary={account.id} />
              </ListItemButton>
            ))}

            {nextPageUrl && (
              <div className="load-more-accounts-btn-container">
                <AppButton
                  className="load-more-accounts-btn"
                  title={"Load More Accounts"}
                  loading={loadingMoreAccounts}
                  sx={{ alignSelf: "center" }}
                  onClick={handleLoadMore}
                />
              </div>
            )}
          </List>
        ) : (
          <div className={"campaign-ads-acc-modal"}>
            {
              <MaterialTreeView
                data={adsAccounts}
                onSelect={(item: any) =>
                  isGoogleAnalytics || isSnapchatOrganization
                    ? onSelectGoogleAnalytics(idKey, idName, item)
                    : onSelectAdsItem(
                        item,
                        idKey,
                        idName,
                        currencyKey,
                        accountsModalType
                      )
                }
                loading={isModalLoading}
                onChildSelect={(item: any) =>
                  isGoogleAnalytics || isSnapchatOrganization
                    ? onSelectGoogleAnalytics(idKey, idName, item)
                    : onSelectAdsItem(
                        item,
                        idKey,
                        idName,
                        currencyKey,
                        accountsModalType
                      )
                }
              />
            }
          </div>
        )}
      </AppModal>
    );
  };

  const renderHeader = (title: string, image: string) => {
    return (
      <div className="ads-login-header-container">
        <img className="ads-login-icon" src={image} alt="" />
        <p className="ads-login-title">{title}</p>
        <p className="ads-login-description">
          <AppText>Please enter your credentials to proceed.</AppText>
        </p>
      </div>
    );
  };

  const renderAccounts = () => {
    return (
      <div className="ads-login-container">
        {_renderAdAccounts()}
        {renderAddAdAccountModal()}
        {renderAdAccountIdsList()}

        {Account === "FACEBOOK" || Account === "INSTAGRAM" ? (
          <div className="ads-login-header-container">
            {renderHeader("Facebook Ads", "images/global/facebook.png")}
            <SocialAdsAccountSelection
              type={Account}
              loader={fbSwitchAccountLoader}
              adsAccountDetails={facebookAds}
              switchAccount={() => switchAccount(Account)}
              connect={() => fbAdsLogin()}
            />
            <p
              onClick={() =>
                sweetAlert(
                  "Tap the Edit button and tap Switch next to your Facebook Profile. Then tap Edit Settings on the Facebook connect pop-up. Make sure your page is selected there. You will then see all of the pages listed that you have access to and you can select them here."
                )
              }
              className="fb-page-text"
            >
              Don't see your Facebook page?
            </p>
          </div>
        ) : Account === "TIKTOK" ? (
          isTiktokConnectLoading ? (
            <Oval
              height={40}
              width={40}
              color={"var(--color-primary)"}
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="var(--text-grey)"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            <div className="ads-login-header-container">
              {renderHeader("Tiktok Ads", "images/global/tiktok.png")}
              <SocialAdsAccountSelection
                type={Account}
                adsAccountDetails={tiktokAds}
                connect={removeTikTokPlatform}
                loader={isTiktokSwitchAccLoading}
                isConnectLoading={isTiktokConnectLoading}
                switchAccount={() => switchAccount(Account)}
                state={{
                  Account: "TIKTOK",
                  redirectUrl: redirectUrl && redirectUrl.replace("&", ","),
                  id: workspaceId,
                }}
              />
            </div>
          )
        ) : Account === "SNAPCHAT" ? (
          isSnapchatConnectLoading ? (
            <Oval
              height={40}
              width={40}
              color={"var(--color-primary)"}
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="var(--text-grey)"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            <div className="ads-login-header-container">
              {renderHeader("Snapchat Ads", "images/global/snapchat.png")}
              <SocialAdsAccountSelection
                type={Account}
                adsAccountDetails={snapchatAds}
                connect={removeSnapchatPlatform}
                loader={isSnapchatSwitchAccLoading}
                isConnectLoading={isSnapchatConnectLoading}
                switchAccount={() => switchAccount(Account)}
                switchSnapchatOrganization={() =>
                  _getSnapchatOrganizations(Account)
                }
                organizationLoader={organizationLoader}
                state={{ Account: "SNAPCHAT", redirectUrl, id: workspaceId }}
              />
            </div>
          )
        ) : Account === "LINKEDIN" ? (
          isLinkedinConnectLoading ? (
            <Oval
              height={40}
              width={40}
              color={"var(--color-primary)"}
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="var(--text-grey)"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            <div className="ads-login-header-container">
              {renderHeader("Linkedin Ads", "images/global/linkedin.png")}
              <SocialAdsAccountSelection
                type={Account}
                adsAccountDetails={linkedinAds}
                connect={removeLinkedinPlatform}
                loader={isLinkedinSwitchAccLoading}
                isConnectLoading={isLinkedinConnectLoading}
                switchAccount={() => switchAccount(Account)}
                state={{ Account: "LINKEDIN", redirectUrl, id: workspaceId }}
              />
            </div>
          )
        ) : Account === "BING_ADS" ? (
          isBingConnectLoading ? (
            <Oval
              height={40}
              width={40}
              color={"var(--color-primary)"}
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="var(--text-grey)"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            <div className="ads-login-header-container">
              {renderHeader("Bing Ads", "images/global/bing.png")}
              <SocialAdsAccountSelection
                type={Account}
                adsAccountDetails={bingAds}
                connect={removeBingAdPlatform}
                loader={isBingSwitchAccLoading}
                isConnectLoading={isBingConnectLoading}
                switchAccount={() => switchAccount(Account)}
                state={{ Account: "BING_ADS", redirectUrl, id: workspaceId }}
              />
            </div>
          )
        ) : Account === "SPOTIFY" ? (
          isSpotifyConnectLoading ? (
            <Oval
              height={40}
              width={40}
              color={"var(--color-primary)"}
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="var(--text-grey)"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            <div className="ads-login-header-container">
              {renderHeader("Spotify Ads", "images/global/spotify-logo.png")}
              <SocialAdsAccountSelection
                type={Account}
                adsAccountDetails={spotifyAds}
                connect={removeSpotifyAdPlatform}
                loader={isSpotifySwitchAccLoading}
                isConnectLoading={isSpotifyConnectLoading}
                switchAccount={() => switchAccount(Account)}
                state={{ Account: "SPOTIFY", redirectUrl, id: workspaceId }}
              />
            </div>
          )
        ) : Account === "GOOGLE_ANALYTICS" ? (
          <div className="ads-login-header-container">
            {renderHeader(
              "Google Analytics",
              "images/global/google_analytics.png"
            )}

            <SocialAdsAccountSelection
              type={Account}
              loader={googleAnalyticsSwitchAccountLoader}
              adsAccountDetails={googleAnalytics}
              switchAccount={() => switchAccount(Account)}
              connect={() => store.dispatch(fbAdsLogin())}
              switchPropertyId={() => _getGoogleAnalyticsProperties(Account)}
              switchViewId={() => _getGoogleAnalyticsViewIds(Account)}
              propertyLoader={googleAnalyticsSwitchPropertyLoader}
              viewIdLoader={googleAnalyticsSwitchViewIdLoader}
            />
          </div>
        ) : (
          <div className="ads-login-header-container">
            {renderHeader("Google Ads", "images/global/google-icon.png")}

            {isCreatingAdAccount ? (
              <Oval
                height={40}
                width={40}
                color={"var(--color-primary)"}
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="var(--text-grey)"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              <SocialAdsAccountSelection
                type={Account}
                adsAccountDetails={googleAdwords}
                loader={googleSwitchAccountLoader}
                switchAccount={() => switchAccount(Account)}
                loading={isModalLoading}
                googleAdAccountId={googleAdAccountId}
                setGoogleAdAccountId={setGoogleAdAccountId}
                setIsGoogleAccManager={setIsGoogleAccManager}
                isGoogleAccManager={isGoogleAccManager}
                setIsAddAdAccModal={setIsAddAdAccModal}
                setIsShowSelectedAccIds={setIsShowSelectedAccIds}
                googleSelectedAdAccountList={googleSelectedAdAccountList}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <AppContainer>
      <MaterialeCard>{renderAccounts()}</MaterialeCard>
      <div className="ads-login-btn-container">
        <AppButton
          title="Back"
          className="account-back-btn"
          onClick={(e: any) => {
            setOpenPopover(true);
            setPopoverAnchorEl(e.currentTarget);
          }}
        />
        <AppPopover
          open={openPopover}
          text={formatMessageInPlaceholder(
            "Please ensure to select your ad account before proceeding. Your selection is essential for accurate ad placement. Thank you!"
          )}
          onClose={() => setOpenPopover(false)}
          isShowIcon={true}
          anchorEl={popoverAnchorEl}
          isShowConfirmBtn={true}
          isShowCancelBtn={true}
          onConfirm={() => setOpenPopover(false)}
          onCancel={() =>
            Account === "LINKEDIN" ||
            Account === "TIKTOK" ||
            Account === "SNAPCHAT" ||
            Account === "BING_ADS" ||
            Account === "SPOTIFY"
              ? self.close()
              : window.open(
                  `${redirectUrl}&state=${JSON.stringify(urlState)}`,
                  "_self"
                )
          }
          // styles={{ width: "100% }}
        />
        {((Account === "GOOGLE" && googleAdwords?.refreshToken && (googleAdwords?.accountId || isRebilling)) ||
          (Account === "FACEBOOK" && facebookAds?.refreshToken && (facebookAds?.accountId || isRebilling)) ||
          (Account === "TIKTOK" && tiktokAds?.accountId) ||
          (Account === "LINKEDIN" && linkedinAds?.accountId) ||
          (Account === "SNAPCHAT" && snapchatAds?.accountId) ||
          (Account === "BING_ADS" && bingAds?.accountId) ||
          (Account === "SPOTIFY" && spotifyAds?.accountId) ||
          (Account === "GOOGLE_ANALYTICS" &&
            googleAnalytics?.accountId &&
            googleAnalytics?.propertyId &&
            (googleAnalytics?.propertyId?.split("-")?.[0] !== "UA" ||
              googleAnalytics?.viewId))) && (
          <AppButton
            title="Accept"
            className="account-Accept-btn"
            onClick={() => {
              if (
                IS_GOOGLE_DISABLED &&
                Account === "GOOGLE" &&
                googleAdwords?.refreshToken &&
                googleSelectedAdAccountList.length &&
                googleAdAccountId
              ) {
                updateUserDataAndChatbot(
                  "googleAdAccountId",
                  googleAdAccountId
                );
                updateUserDataAndChatbot(
                  "googleSelectedAdAccountList",
                  googleSelectedAdAccountList
                );

                if (isGoogleAccManager === "Yes") {
                  actionAlert(
                    "Please accept the invitation email from Advert Generator AI Ads.",
                    () =>
                      window.open(
                        `${redirectUrl} & state= ${JSON.stringify(urlState)}`,
                        "_self"
                      ),
                    "Ok",
                    "",
                    false,
                    "https://ads.google.com/aw/accountaccess/managers?ocid=307883457&euid=68984915&__u=8197267835&uscid=307883457&__c=3681439993&authuser=0&subid=ae-en-et-g-aw-c-home-awhp_xin1_signin%21o2-awhp-hv-01-22",
                    "Click here to accept Invitation"
                  );
                } else {
                  actionAlert(
                    "Please have your Google Ad Account Admin accept the invitation email from Advert Generator AI Ads.",
                    () =>
                      window.open(
                        `${redirectUrl}&state=${JSON.stringify(urlState)}`,
                        "_self"
                      ),
                    "Ok",
                    "",
                    false
                  );
                }
              } else {
                console.log(facebookAds, "facebookAd");
                if (Account === "FACEBOOK" && !facebookAds?.accountId) {
                  window.open(
                    `${redirectUrl}&isFbNotConnected=true&state=${JSON.stringify(
                      urlState
                    )}`,
                    "_self"
                  );
                } else {
                  if (
                    Account === "LINKEDIN" ||
                    Account === "TIKTOK" ||
                    Account === "SNAPCHAT" ||
                    Account === "BING_ADS" ||
                    Account === "SPOTIFY"
                  ) {
                    if (Account === "LINKEDIN" && linkedinAds?.accountId) {
                      updateUserDataAndChatbot("adsPlatforms", [Account]);
                    } else if (Account === "TIKTOK" && tiktokAds?.accountId) {
                      updateUserDataAndChatbot("adsPlatforms", [Account]);
                    } else if (
                      Account === "SNAPCHAT" &&
                      snapchatAds?.accountId
                    ) {
                      updateUserDataAndChatbot("adsPlatforms", [Account]);
                    } else if (Account === "BING_ADS" && bingAds?.accountId) {
                      updateUserDataAndChatbot("adsPlatforms", [Account]);
                    } else if (Account === "SPOTIFY" && bingAds?.accountId) {
                      updateUserDataAndChatbot("adsPlatforms", [Account]);
                    }

                    self.close();
                  } else {
                    window.open(
                      `${redirectUrl}&state=${JSON.stringify(urlState)}`,
                      "_self"
                    );
                  }
                }
              }
            }}
          />
        )}
      </div>
    </AppContainer>
  );
};

export default AdsLogin;
